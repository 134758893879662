<template>
    <svg width="103" height="36" viewBox="0 0 103 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.8492 6.76132C73.6698 -5.03799 45.5789 -0.848128 16.8615 15.0835C16.511 15.278 16.6454 15.8098 17.0463 15.8098H19.8428C19.8976 15.8098 19.9539 15.7973 20.0039 15.775C59.7117 -1.95219 70.4923 2.9793 89.544 7.40155C89.9448 7.49459 90.1817 7.00378 89.8492 6.76132Z" fill="#1D8327"/>
    <path d="M100.618 15.9035L86.3888 23.2883L86.3581 23.1794C83.7844 14.0209 80.9021 3.76422 64.2637 0.0252332C77.4095 -0.150443 89.4893 0.510711 100.618 15.9035Z" fill="#65D470"/>
    <path d="M7.60924 36.0002C12.4124 36.0002 15.2817 33.6239 15.2817 29.8952V29.8825C15.2817 26.9374 13.5121 25.3827 9.63163 24.6243L7.74828 24.2577C5.77645 23.8659 4.94221 23.2971 4.94221 22.2606V22.248C4.94221 21.161 5.92813 20.4152 7.5966 20.4152C8.60077 20.4152 9.62587 20.7195 10.2011 21.4602C10.5911 21.9624 11.0962 22.4629 11.732 22.4629H13.5143C14.2752 22.4629 14.8892 21.8032 14.661 21.0773C13.7346 18.1302 10.7367 16.7749 7.57132 16.7749C3.37486 16.7749 0.341278 19.0754 0.328638 22.6525V22.6651C0.328638 25.5091 1.99711 27.3292 5.85229 28.075L7.73564 28.4416C9.87179 28.8587 10.6807 29.339 10.6807 30.3628V30.3755C10.6807 31.5383 9.49259 32.3599 7.68508 32.3599C6.70798 32.3599 5.52063 32.1044 4.8779 31.3647C4.44324 30.8644 3.9226 30.3123 3.25987 30.3123H1.37488C0.628213 30.3123 0.0171798 30.9492 0.223867 31.6667C1.1122 34.7504 4.38123 36.0002 7.60924 36.0002Z" fill="white"/>
    <path d="M20.0915 34.5354C20.241 35.1079 20.7582 35.5073 21.3499 35.5073H23.7209C24.3095 35.5073 24.8248 35.112 24.9772 34.5435L27.6849 24.4437C27.7024 24.3787 27.7612 24.3336 27.8285 24.3336C27.8955 24.3336 27.9542 24.3784 27.9718 24.4431L30.7269 34.5488C30.8812 35.1147 31.3951 35.5073 31.9817 35.5073H34.3319C34.9236 35.5073 35.4407 35.1079 35.5902 34.5354L39.6759 18.8972C39.8912 18.0731 39.2693 17.2679 38.4175 17.2679H36.3926C35.7714 17.2679 35.237 17.7071 35.1166 18.3165L32.9574 29.2498C32.9442 29.3163 32.8859 29.3643 32.8181 29.3643C32.7529 29.3643 32.6962 29.3199 32.6804 29.2567L29.9324 18.2533C29.7878 17.6742 29.2675 17.2679 28.6705 17.2679H26.9942C26.3934 17.2679 25.8708 17.6793 25.7299 18.2633L23.0765 29.2559C23.0611 29.3195 23.0042 29.3643 22.9388 29.3643C22.8714 29.3643 22.8133 29.3167 22.8 29.2505L20.6056 18.3126C20.4837 17.7051 19.95 17.2679 19.3304 17.2679H17.2642C16.4124 17.2679 15.7905 18.0731 16.0058 18.8972L20.0915 34.5354Z" fill="white"/>
    <path d="M41.7399 34.2067C41.7399 34.925 42.3222 35.5073 43.0405 35.5073H45.0781C45.7964 35.5073 46.3787 34.925 46.3787 34.2067V18.5685C46.3787 17.8502 45.7964 17.2679 45.0781 17.2679H43.0405C42.3222 17.2679 41.7399 17.8502 41.7399 18.5685V34.2067Z" fill="white"/>
    <path d="M48.9613 34.2067C48.9613 34.925 49.5437 35.5073 50.262 35.5073H52.0594C52.7777 35.5073 53.3601 34.925 53.3601 34.2067V25.2937C53.3601 25.2102 53.4277 25.1425 53.5112 25.1425C53.5592 25.1425 53.6044 25.1654 53.6329 25.204L60.8322 34.978C61.0774 35.3108 61.4661 35.5073 61.8794 35.5073H63.5997C64.318 35.5073 64.9003 34.925 64.9003 34.2067V18.5685C64.9003 17.8502 64.318 17.2679 63.5997 17.2679H61.8022C61.0839 17.2679 60.5016 17.8502 60.5016 18.5685V27.3802C60.5016 27.4637 60.4339 27.5315 60.3503 27.5315C60.3023 27.5315 60.2573 27.5088 60.2287 27.4703L53.0548 17.7939C52.8095 17.463 52.4219 17.2679 52.01 17.2679H50.262C49.5437 17.2679 48.9613 17.8502 48.9613 18.5685V34.2067Z" fill="white"/>
    <path d="M75.7405 36.0002C80.8976 36.0002 84.0196 32.9287 84.0196 27.9107V26.8603C84.0196 26.142 83.4373 25.5597 82.719 25.5597H77.5088C76.7904 25.5597 76.2081 26.142 76.2081 26.8603V27.4443C76.2081 28.1626 76.7904 28.7449 77.5088 28.7449H79.2415C79.4071 28.7449 79.5393 28.8828 79.5324 29.0483C79.406 30.919 77.9525 32.1577 75.8416 32.1577C73.1872 32.1577 71.4682 29.9331 71.4682 26.3307V26.3054C71.4682 22.7662 73.0229 20.6174 75.6141 20.6174C76.9273 20.6174 78.2111 21.18 78.8781 22.2583C79.1987 22.7767 79.7007 23.2213 80.3102 23.2213H82.4054C83.1749 23.2213 83.7879 22.5481 83.5488 21.8167C82.4855 18.5647 79.0161 16.7749 75.6141 16.7749C70.141 16.7749 66.7535 20.4405 66.7535 26.356V26.3813C66.7535 32.3726 70.1283 36.0002 75.7405 36.0002Z" fill="white"/>
    <circle cx="93.9784" cy="20.539" r="8.12883" fill="white"/>
    <path d="M93.975 24.8258C96.1184 24.8258 97.5244 23.6084 97.5244 21.7565V17.4012C97.5244 16.7039 97.1415 16.3096 96.4727 16.3096C95.8097 16.3096 95.4268 16.7039 95.4268 17.4012V21.5336C95.4268 22.4938 94.9067 23.0654 93.975 23.0654C93.0377 23.0654 92.5175 22.4938 92.5175 21.5336V17.4012C92.5175 16.7039 92.1346 16.3096 91.4716 16.3096C90.8086 16.3096 90.4199 16.7039 90.4199 17.4012V21.7565C90.4199 23.6084 91.826 24.8258 93.975 24.8258Z" fill="#49BB54"/>
    </svg>
</template>